<script setup lang="ts">
const docus = useDocus()
const { navigation, page } = useContent()
const { navKeyFromPath } = useContentHelpers()

const titleTemplate = computed(() => {
  const appTitleTemplate = docus.value.head?.titleTemplate || `%s · ${docus.value.title}`

  if (page.value) {
    if (page.value.layout === 'page') {
      return page.value.title ? page.value.title : docus.value.title
    }
    if (page.value.title) {
      if (page.value.title.toLowerCase().includes("pathway")) {
        return page.value.title
      }
      return `${page.value.title} | ${docus.value.website}`
    }
  }
  return appTitleTemplate
})

defineProps({
  padded: {
    type: Boolean,
    default: true
  }
})

function isValidUrl(urlString) {
  try {
    return Boolean(new URL(urlString))
  } catch (e) {
    return false
  }
}

function getThumbnail(defaultURL = '/assets/pathway-card.png') {
  let origin = process.client ? window?.location?.origin : docus.value.url
  let thumbnail = page.value?.article?.thumbnail ?? page.value?.thumbnail
  if (thumbnail) {
    if (isValidUrl(thumbnail)) {
      return thumbnail
    } else {
      if(thumbnail.startsWith('/')) {
        return origin += thumbnail
      }else {
        return origin += '/'+ thumbnail
      }
    }
  }

  return origin + defaultURL
}

useHead({
  titleTemplate: titleTemplate.value,
})

if (!page.value?.article?.thumbnail && (!page.value?.thumbnail || !page.value)) {
  if (page.value?._path.includes("/developers")) {
    const breadCrumb = page.value._path.split("/")[2]
    const devIcons = {
      "user-guide": "heroicons:academic-cap-16-solid",
      "api-docs": "heroicons:code-bracket-16-solid",
      "showcases": "heroicons:bolt-16-solid"
    }
    defineOgImageComponent('OGImageDev', {
      headline: 'Developers / ' + breadCrumb,
      title: titleTemplate.value.includes("| Pathway") ? titleTemplate.value.replace("| Pathway", "") : titleTemplate.value,
      description: page.value.description || '',
      icon: devIcons[breadCrumb] || "heroicons:code-bracket-16-solid"
    })
  } else {
    let mainTitle = titleTemplate.value
    if (page.value?._path === "/") {
      mainTitle = mainTitle.replace("Pathway - ", "")
    }
    defineOgImageComponent('OGImage', {
      headline: 'Pathway',
      title: mainTitle.includes("| Pathway") ? mainTitle.replace("| Pathway", "") : mainTitle,
      description: page.value?.description || '',
    })
  }
} else {
  useHead({
    meta: [
      //   // Twitter
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:image', content: getThumbnail() },
      { name: 'twitter:title', content: titleTemplate.value },
      { name: 'twitter:description', content: page.value?.description || docus.value?.description },

      // Open Graph / Facebook
      { property: 'og:type', content: 'website' },
      { property: 'og:title', content: titleTemplate.value },
      { property: 'og:description', content: page.value?.description || docus.value?.description },
      { property: 'og:image', content: getThumbnail('/assets/pathway-thumbnail.gif') },
      { property: 'og:image:width', content: '1200px' },
      { property: 'og:image:height', content: '628px' }
    ]
  })
}

// Article keywords
if (page.value?.keywords) {
  useHead({ meta: [{ name: "keywords", content: page.value.keywords.join(", ") }] })
}

watch(titleTemplate, () => {
  useHead({ titleTemplate: titleTemplate.value })
})

useContentHead(docus.value as any)
</script>

<template>
  <div>
    <AppLoadingBar />
    <Navbar v-if="docus?.header" />
    <slot />
    <AppFooter v-if="docus?.footer" />
  </div>
</template>
