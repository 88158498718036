export default defineNuxtRouteMiddleware(async (to, from) => {
  // Skip middleware on server
  if (import.meta.server) return;

  const { user } = await useAuth()

  if (!user?.value?.provider && to.path.startsWith('/user')) {
    return navigateTo('/get-license', { replace: true })
  }
})
