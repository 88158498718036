import { Auth0Client, User } from '@auth0/auth0-spa-js'
import { jwtDecode } from "jwt-decode"
import { setSourceMapRange } from 'typescript'

export enum AuthenticationProvider {
    Google = "google-oauth2",
    GitHub = "github",
    LinkedIn = "linkedin"
}

export const useAuth = async () => {

    const { authParams } = useRuntimeConfig().public

    // Stop whining during static render
    if (process.server) {
        return {}
    }

    const auth0 = useState("auth0", () => new Auth0Client({
        ...useRuntimeConfig().public.auth,
        cacheLocation: 'localstorage',
        // useRefreshTokens: true
    }))

    const user = useState("user", () => {
        return JSON.parse(localStorage.getItem("user")) ?? {}
    })

    const setUser = (userParam) => {
        localStorage.setItem("user", JSON.stringify(userParam))
        user.value = userParam
    }

    const login = async (provider: AuthenticationProvider) => {
        await auth0.value.loginWithPopup({
            authorizationParams: {
                connection: provider,
                audience: authParams.audience,
                scope: "openid profile email create:licenses"
            }
        })

        const userInfo = await auth0.value.getUser()
        userInfo.provider = provider
        setUser(userInfo)

        await handleTokenExpiry()
    }

    const logout = async () => {
        setUser({})
        await auth0.value.logout({
            logoutParams: {
                returnTo: window.location.origin
            }
        })
    }

    const refreshToken = async () => {
        try {
            await handleTokenExpiry()
        } catch (error) {
            console.error("Error refreshing token:", error)
            await logout()
        }
    }

    const handleTokenExpiry = async () => {
        if (!user.value?.provider) {
            await logout()
            return false
        }
        let token = ""
        try {
            token = await auth0.value.getTokenSilently({
                authorizationParams: {
                    connection: user.value.provider,
                    audience: authParams.audience,
                    scope: 'openid profile email create:licenses',
                }
            })
        } catch (err) {
            await logout()
            return false
        }

        const decoded = jwtDecode(token)

        const tokenExpiry = decoded?.exp ? decoded.exp * 1000 : Date.now() + (24 * 60 * 60 * 1000)
        const expiresIn = tokenExpiry - Date.now()

        if (expiresIn <= 0) {
            // Token expired
            await logout()
        } else {
            const refreshTime = process.env.NODE_ENV === 'production' ? expiresIn : expiresIn * 1000
            setTimeout(refreshToken, refreshTime)
        }
    }

    //auth0.value.getUser().then(setUser)

    if (user.value?.provider) {
        // User exist
        await handleTokenExpiry()
    }

    return { user, auth0, login, logout }
}
