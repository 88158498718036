<script setup lang="ts">
const { element } = useDocSearch()

const onClick = () => element.value.querySelector('button').click()
</script>

<template>
  <div class="relative pointer-events-auto group searcher" @click="onClick">
    <button aria-label="search in the documentation" type="button"
      class="hoverstate text-[#141933] transition hover:text-[#41455B] ring-[#DADEF2] group-hover:ring-[#41455B] flex w-full items-center text-sm rounded-md py-1.5 pl-2 pr-3 shadow-sm ring-1">
      <Icon name="heroicons-outline:search" class="flex-none h-4 w-4" />
      <span class="text-xs font-medium block text-[#141933] searcher--text ml-2">Search</span>
      <span class="flex-none pl-3 ml-auto text-xs font-semibold u-text-gray-400 lg:block searcher--shortcut">⌘K</span>
    </button>
  </div>
</template>

<style scoped>
@media (max-width: 1270px) {
  .searcher--shortcut {
    display: none;
  }

  .nav--dev .searcher--shortcut {
    display: block;
  }
}

@media (max-width: 1200px) {
  .searcher--text {
    display: none;
  }

  .nav--dev .searcher--text {
    display: block;
  }
}
</style>
