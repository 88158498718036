<template>
    <section class="bg-[#151E33] md:py-[10rem] py-16 pt-[8rem] -mt-[4rem] hero">
        <div class="max-w-7xl mx-auto flex flex-col lg:flex-row justify-between items-center gap-4 px-6 lg:px-4">
            <div class="w-full ">
                <div>
                    <h1 class="lg:text-6xl lg:text-left font-medium text-white text-center text-4xl whitespace-nowrap">
                        AI, served fresh</h1>
                    <span class="lg:text-3xl lg:text-left text-white mt-2 block font-normal text-center text-[1.3rem]">
                        Powering your RAG and ETL at scale</span>
                </div>
                <div class="flex justify-center gap-4 flex-wrap mt-12 lg:justify-start lg:flex-nowrap">
                    <div
                        class="flex items-center justify-center lg:justify-start lg:flex-col lg:items-start gap-4 text-[#ABAEB4] transition">
                        <Icon name="bxl:python" class="w-8 h-8" />
                        <span class="block max-w-[23ch]">Easily set up data ingest from 300+
                            sources with automatic synchronization.</span>
                    </div>
                    <div
                        class="flex items-center justify-center lg:justify-start lg:flex-col lg:items-start gap-4 text-[#ABAEB4] transition">
                        <Icon name="heroicons:bolt-20-solid" class="w-8 h-8" />
                        <span class="block max-w-[23ch]">Serve vector search, real-time features,
                            anomaly alerts. Updated in milliseconds.</span>
                    </div>
                    <div
                        class="flex items-center justify-center lg:justify-start lg:flex-col lg:items-start gap-4 text-[#ABAEB4] transition">
                        <Icon name="mdi:robot" class="w-8 h-8" />

                        <span class="block max-w-[23ch]">Get accurate AI insights from
                            terabytes of connected documents and data tables.</span>
                    </div>
                </div>
                <div class="flex gap-8 mt-12 flex-wrap justify-center lg:justify-start">
                    <NuxtLink
                        class="pw-button--primary flex items-center text-xl w-max gap-2 bg-primary-500 p-4 px-10 text-white rounded-es-xl rounded-se-xl shadow-[0px_8.0px_8.0px_rgba(0,0,0,0.38)] transition"
                        to="/developers/templates">
                        App templates
                        <Icon name="heroicons:arrow-right-16-solid" class="w-4 h-4 button-arrow" />
                    </NuxtLink>
                    <NuxtLink
                        class="flex items-center text-xl w-max gap-2 pw-button--primary-white p-4 px-10 shadow-[0px_8.0px_8.0px_rgba(0,0,0,0.38)] transition rounded-es-xl rounded-se-xl"
                        to="/developers/user-guide/introduction/welcome">
                        Setup guide
                        <Icon name="heroicons:arrow-right-16-solid" class="w-4 h-4 text-primary-700 button-arrow" />
                    </NuxtLink>

                    <!--                    <Calendly name="GetStarted" href="https://calendly.com/d/dj2-4jd-3tw/introductory-call-pathway"
                        class="flex items-center text-xl w-max gap-2 pw-button--primary-white p-4 px-10 shadow-[0px_8.0px_8.0px_rgba(0,0,0,0.38)] transition rounded-es-xl rounded-se-xl">
                        Let's talk
                        <Icon name="heroicons:arrow-right-16-solid" class="w-4 h-4 text-primary-700 button-arrow" />
                    </Calendly>
-->
                </div>
            </div>
            <div class="mt-8">
                <div class=" ml-auto">
                    <img src="/assets/landing/pathway-hero-image.svg" alt="" width="453" height="338"
                        class="w-[clamp(20rem,60vw,45rem)]" />
                </div>
            </div>
        </div>

    </section>
</template>

<style scoped lang="scss">
.hero {
    background: rgb(20, 29, 45);
    background: linear-gradient(90deg, rgba(20, 29, 45, 1) 42%, rgba(21, 32, 51, 1) 50%, rgba(39, 41, 176, 1) 100%);
}
</style>
