export default defineNuxtPlugin(async () => {
    const ghStars = useState("gh-stars", () => null)

    onNuxtReady(async () => {
        if (!ghStars.value) {
            Promise.all([$fetch("https://api.github.com/repos/pathwaycom/pathway"), $fetch("https://api.github.com/repos/pathwaycom/llm-app")]).then((data) => {
                data.forEach((el) => {
                    ghStars.value += el?.stargazers_count || 0
                })
            })
        }
    })
})
