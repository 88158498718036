<template>
  <AppLayout>
    <NuxtPage :page-key="route => route.path + String(Math.random())" />
  </AppLayout>
</template>

<script setup>



onMounted(() => {

  /* Signals */
  (function () {
    if (typeof window === 'undefined') return;
    if (typeof window.signals !== 'undefined') return;
    var script = document.createElement('script');
    script.src = 'https://cdn.cr-relay.com/v1/site/fb0135f9-6ee0-4900-9eee-0c7c7b46983f/signals.js';
    script.async = true;
    window.signals = Object.assign(
      [],
      ['page', 'identify', 'form'].reduce(function (acc, method) {
        acc[method] = function () {
          signals.push([method, arguments]);
          return signals;
        };
        return acc;
      }, {})
    );
    document.head.appendChild(script);
  })();

  useHead({
    link: [
      {
        rel: 'stylesheet',
        href: 'https://cdn.jsdelivr.net/npm/katex@0.15.0/dist/katex.min.css',
        as: 'style',
        onload: 'this.onload=null;this.rel="stylesheet"'
      }
    ]
  })
})

useSchema()
</script>
