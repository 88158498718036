import type { RouterConfig } from '@nuxt/schema'

export default <RouterConfig>{
  async scrollBehavior (to, _from, savedPosition) {

    if (history.state.stop) { return }

    if (history.state.smooth) {
      return {
        el: history.state.smooth,
        behavior: 'smooth'
      }
    }
    if (!to.hash && _from?.fullPath?.includes('/glossary/')) {
      return {
        top: 0,
        behavior: 'smooth'
      }
    }

    if (to.hash) {
      await nextTick()
      let el = document.getElementById(to.hash.replace("#", "")) as HTMLElement

      if (!el) {
        await new Promise(resolve => setTimeout(resolve, 100))
        el = document.getElementById(to.hash.replace("#", "")) as HTMLElement
        if (!el) { return }
      }

      const { marginTop } = getComputedStyle(el)
      const marginTopValue = parseInt(marginTop)
      const offset = el.offsetTop - marginTopValue

      return {
        top: offset,
        behavior: 'smooth'
      }
    }

    // Scroll to the top of the window
    if (savedPosition) {
      return savedPosition
    } else {
      if (to?.query?.modal || _from?.query?.modal) {
        return;
      }
      return { top: 0 }
    }
  }
}
