export default defineAppConfig({
  ui: {
    primary: 'pathway',
    select: {
      default: {
        trailingIcon: "heroicons:chevron-down-20-solid"
      }
    },
    accordion: {
      default: {
        openIcon: "heroicons:chevron-right-20-solid"
      }
    },
    table: {
      default: {
        sortAscIcon: 'octicon:sort-asc-24',
        sortDescIcon: 'octicon:sort-desc-24',
        sortButton: {
          icon: 'octicon:arrow-switch-24'
        },
        loadingState: {
          icon: 'octicon:sync-24'
        },
        emptyState: {
          icon: 'octicon:database-24'
        }
      }
    }
  },
  docus: {
    website: 'Pathway',
    title: 'Pathway - Power Your AI with Live Data',
    description: 'Python ETL framework for stream processing, real-time analytics, RAG, and LLM pipelines.',
    layout: 'docs',
    // image: '/assets/pathway-thumbnail.gif',
    url: 'https://pathway.com',
    debug: false,
    socials: {
      twitter: '@pathway_com',
      // discourse: 'https://forum.pathway.com/',
      discord: 'https://discord.com/invite/pathway',
      github: 'https://github.com/pathwaycom/'
    },
    github: {
      root: 'docs/content',
      edit: false,
      releases: false
    },
    cover: {
      src: '/assets/pathway-thumbnail.gif',
      alt: 'Pathway - Power Your AI with Live Data'
    },
    aside: {
      level: 1,
      collapsed: true
    },
    header: {
      title: false,
      logo: true
    },
    footer: {
      credits: {
        icon: '',
        text: '',
        href: ''
      },
      content: {
        text: '',
        href: ''
      },
      icons: [
        {
          label: 'Linkedin',
          href: 'https://www.linkedin.com/company/pathway/',
          component: 'fa6-brands:linkedin'
        }
      ]
    }
  }
})
