
// const config = useRuntimeConfig()
export const useSchema = () => {
    const { page } = useContent()
    const article = page.value?.article
    if (article) {
        if (page.value?._path.includes('developers')) {
            useSchemaOrg([
                defineArticle({
                    '@type': 'TechArticle',
                    image: article.thumbnail,
                    datePublished: article.date,
                    author: {
                        name: page.value?.author.name
                    }
                })
            ])
        }
        else if (page.value?._path.includes('news')) {
            useSchemaOrg([
                defineArticle({
                    '@type': 'NewsArticle',
                    image: article.thumbnail,
                    datePublished: article.date,
                    author: {
                        name: page.value?.author.name
                    }
                })
            ])
        } else {
            useSchemaOrg([
                defineArticle({
                    '@type': 'BlogPosting',
                    image: article.thumbnail,
                    datePublished: article.date,
                    author: {
                        name: page.value?.author.name
                    }
                })
            ])
        }
    } else {
        useSchemaOrg([
            defineOrganization({
                name: 'Pathway',
                logo: '/assets/pathway-card.png',
                sameAs: [
                    'https://twitter.com/pathway_com',
                    'https://github.com/pathwaycom/'
                ]
            }),
            defineWebPage(),
        ])
    }

}
