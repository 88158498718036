import revive_payload_client_4sVQNw7RlN from "/mnt/scratch/pathwaytester/.jenkins/workspace/website_develop/public/website3/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/mnt/scratch/pathwaytester/.jenkins/workspace/website_develop/public/website3/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/mnt/scratch/pathwaytester/.jenkins/workspace/website_develop/public/website3/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/mnt/scratch/pathwaytester/.jenkins/workspace/website_develop/public/website3/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/mnt/scratch/pathwaytester/.jenkins/workspace/website_develop/public/website3/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/mnt/scratch/pathwaytester/.jenkins/workspace/website_develop/public/website3/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/mnt/scratch/pathwaytester/.jenkins/workspace/website_develop/public/website3/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/mnt/scratch/pathwaytester/.jenkins/workspace/website_develop/public/website3/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/mnt/scratch/pathwaytester/.jenkins/workspace/website_develop/public/website3/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/mnt/scratch/pathwaytester/.jenkins/workspace/website_develop/public/website3/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_BqLkNUef68 from "/mnt/scratch/pathwaytester/.jenkins/workspace/website_develop/public/website3/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import slideovers_X7whXyrIWR from "/mnt/scratch/pathwaytester/.jenkins/workspace/website_develop/public/website3/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_oqljskDzoR from "/mnt/scratch/pathwaytester/.jenkins/workspace/website_develop/public/website3/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ETtiVmZNmO from "/mnt/scratch/pathwaytester/.jenkins/workspace/website_develop/public/website3/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_OVoKJro5pc from "/mnt/scratch/pathwaytester/.jenkins/workspace/website_develop/public/website3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_WLsn00x1qh from "/mnt/scratch/pathwaytester/.jenkins/workspace/website_develop/public/website3/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import documentDriven_cMiI6j6ghD from "/mnt/scratch/pathwaytester/.jenkins/workspace/website_develop/public/website3/node_modules/@nuxt/content/dist/runtime/legacy/plugins/documentDriven.js";
import docsearch_UirAB29dWu from "/mnt/scratch/pathwaytester/.jenkins/workspace/website_develop/public/website3/plugins/docsearch.ts";
import github_stars_ceLZmCzYhy from "/mnt/scratch/pathwaytester/.jenkins/workspace/website_develop/public/website3/plugins/github-stars.ts";
import hotjar_client_HJG8no80GI from "/mnt/scratch/pathwaytester/.jenkins/workspace/website_develop/public/website3/plugins/hotjar.client.ts";
import menu_zERWsRm4Vj from "/mnt/scratch/pathwaytester/.jenkins/workspace/website_develop/public/website3/plugins/menu.ts";
import showToast_client_i3Ts44oG79 from "/mnt/scratch/pathwaytester/.jenkins/workspace/website_develop/public/website3/plugins/showToast.client.js";
import vue_ga_client_oME72Wf8qP from "/mnt/scratch/pathwaytester/.jenkins/workspace/website_develop/public/website3/plugins/vue-ga.client.js";
import vue_gtm_client_Cm24Dsc5BN from "/mnt/scratch/pathwaytester/.jenkins/workspace/website_develop/public/website3/plugins/vue-gtm.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_BqLkNUef68,
  slideovers_X7whXyrIWR,
  modals_oqljskDzoR,
  colors_ETtiVmZNmO,
  plugin_client_OVoKJro5pc,
  plugin_WLsn00x1qh,
  documentDriven_cMiI6j6ghD,
  docsearch_UirAB29dWu,
  github_stars_ceLZmCzYhy,
  hotjar_client_HJG8no80GI,
  menu_zERWsRm4Vj,
  showToast_client_i3Ts44oG79,
  vue_ga_client_oME72Wf8qP,
  vue_gtm_client_Cm24Dsc5BN
]