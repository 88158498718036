<script setup lang="ts">
const { page } = useContent()
const docus = useDocus()
const logo = computed(() => docus.value.header?.logo || false)
const title = computed(() => docus.value.header?.title || docus.value.title)
const path = computed(() => {
  if (page.value?._path) {
    return page.value?._path
  } else if (process.client) {
    return window.location.pathname
  }
})
</script>

<template>
  <NuxtLink class="flex flex-col items-center flex-none text-primary-500"
    :to="path?.startsWith('/developers') ? '/developers/user-guide/introduction/welcome' : '/'"
    :aria-label="docus?.header?.title">
    <!-- Only Logo -->
    <!-- TODO: use component is -->
    <Logo v-if="logo" />
    <!-- Only title -->
    <span v-else class="text-xl font-bold u-text-gray-900 hover:u-text-gray-500">
      {{ title }}
    </span>
    <span class="ml-[2rem] -mt-[8px] block" v-if="path?.startsWith('/developers')">for developers</span>
  </NuxtLink>
</template>
