<template>
    <span>{{ roundToThousands(stars) || 6 }}</span>
</template>


<script setup>
const stars = useState("gh-stars")

function roundToThousands (num) {
    if (num < 10000) {
        return Math.round(num / 1000);
    } else {
        return (Math.round(num / 100) / 10).toFixed(1);
    }
}
</script>
