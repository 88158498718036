import Toast, { POSITION, useToast } from "vue-toastification";
import "vue-toastification/dist/index.css";

const toast = useToast();

export default defineNuxtPlugin(nuxtApp => {
    nuxtApp.vueApp.use(Toast, {
        position: POSITION.BOTTOM_RIGHT,
    });
    nuxtApp.provide('showToast', (msg, type, time = 3000) => toast[type](msg, { timeout: time, toastClassName: "pathway-toast" }))
})