<template>
  <ul>
    <li v-for="link in links" :key="link._path" :class="{
      'ml-2': parent?.icon,
      'pl-4': level > 0,
      '': level > 0 || !hasNesting,
      'border-l border-primary-500': isActive(link) && !isCollapseLink(link),
      'u-border-gray-100 hover:u-border-gray-300': !isActive(link),
    }">
      <template v-if="link.children">
        <NuxtLink v-if="isCollapseLink(link)" :to="link._path"
          class="group flex w-full cursor-pointer items-center justify-between py-1.5 text-sm font-medium pl-4"
          :class="{ 'text-primary-500 border-l border-primary-500': isActive(link) }">
          <span class="flex items-center text-left">
            <Icon v-if="link?.navigation?.icon || link.icon" :name="link?.navigation?.icon || link.icon"
              class="w-4 h-4 mr-2" />
            <span>{{ link?.navigation?.title || link.title || link._path }}</span>
          </span>
          <span class="flex" @click.prevent="toggleCollapse(link)">
            <Icon :name="isCollapsed(link) ? 'lucide:chevrons-up-down' : 'lucide:chevrons-down-up'"
              class="w-3 h-3 u-text-gray-400 group-hover:u-text-gray-800" />
          </span>
        </NuxtLink>
        <button v-else
          class="u-text-gray-900 group flex w-full cursor-pointer items-center justify-between py-1.5 text-sm font-medium pl-4"
          @click="toggleCollapse(link)">
          <span class="flex items-center text-left">
            <Icon v-if="link?.navigation?.icon || link.icon" :name="link?.navigation?.icon || link.icon"
              class="w-4 h-4 mr-2" />
            <span>{{ link?.navigation?.title || link.title || link._path }}</span>
          </span>
          <span class="flex">
            <Icon :name="isCollapsed(link) ? 'lucide:chevrons-up-down' : 'lucide:chevrons-down-up'"
              class="w-3 h-3 u-text-gray-400 group-hover:u-text-gray-800" />
          </span>
        </button>
      </template>
      <NuxtLink v-else v-if="link?.navTitle !== false && link.title !== parent?.title"
        :to="link.redirect ? link.redirect : link._path" class="flex items-center justify-between py-1.5 text-sm"
        :exact="link.exact" :class="{
          'pl-4': level > 0 || !hasNesting,
          'text-primary-500 font-medium pl-4': isActive(link),
          'u-text-gray-500 hover:u-text-gray-900 pl-4': !isActive(link),
        }">
        <span class="inline-flex items-center">
          <Icon v-if="link?.navigation?.icon || link.icon" :name="link?.navigation?.icon || link.icon"
            class="w-4 h-4 mr-1" />
          <span>{{ link?.navTitle || link?.navigation?.title || link.title ||
            link._path }}</span>
        </span>
      </NuxtLink>
      <DocsAsideTree v-show="!isCollapsed(link)" v-if="link.children?.length && (max === null || level + 1 < max)"
        :links="link.children" :level="level + 1" :parent="link" :max="max" class="py-2" />
    </li>
  </ul>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'

const props = defineProps({
  links: {
    type: Array as PropType<any>,
    default: () => []
  },
  level: {
    type: Number,
    default: 0
  },
  max: {
    type: Number,
    default: null
  },
  parent: {
    type: Object as PropType<any>,
    default: null
  },
  isMobile: {
    type: Boolean,
    default: false
  }
})
const route = useRoute()
const docus = useDocus()
// const collapsedMap = useState(`docus-docs-aside-collapse-map-${props.parent?._path || '/'}`, () => {
const collapsedMap = useState(`docus-docs-aside-collapse-map`, () => {
  if (props.level === 0) {
    return {}
  }
  return props.links
    .filter(link => !!link.children)
    .reduce((map, link) => {
      map[link._path] = true
      return map
    }, {})
})

const isActive = (link) => {
  return (route.path === link._path) || (route.path === link._path + '/')
}

const isCollapsed = (link) => {
  if (link.children) {
    // Directory has been toggled manually, use its state
    if (typeof collapsedMap.value[link._path] !== 'undefined') {
      return collapsedMap.value[link._path]
    }

    // Return value grabbed from the link
    if (link?.collapsed) { return link?.collapsed }

    if (docus.value.aside?.collapsed) { return docus.value.aside?.collapsed }
  }

  return false
}
const isCollapseLink = (link) => {
  if (!link?.children) {
    return false
  }

  if (link.noChildren) {
    delete link.children
    return false
  }
  return link?.children.some((children) => children._path === link._path)
}
const toggleCollapse = (link) => {
  collapsedMap.value[link._path] = !isCollapsed(link)
}

props.links.forEach((link) => {
  if (route.path.includes(link._path)) {
    collapsedMap.value[link._path] = false //isCollapsed(link._path)
    if (link?.article?.tags) {
      collapsedMap.value[`${link._path.split("/").splice(0, 3).join("/")}?tag=${link.article.tags[1]}`] = false

    }
  }
}) // /developers/templates/linear_regression_with_kafka
const hasNesting = computed(() => props.links.some((link: any) => link.children))
</script>
