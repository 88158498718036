<script setup lang="ts">
// const docus = useDocus()
const { page } = useContent()
const path = computed(() => {
  if (page.value?._path) {
    return page.value?._path
  } else if (process.client) {
    return window.location.pathname
  }
})
const docus = useDocus()
const socials = computed(() => docus.value?.socials || {})
// const isDevelopers = computed(() => path.value?.startsWith('/developers'))
// const socialIcons = ref(null)
// const icons = computed(() => docus.value.footer?.icons || [])
// const socialIconsCount = computed(() => Object.entries(docus.value.socials).filter(([_, v]) => v).length)
// const nbSocialIcons = computed(() => (socialIcons.value ? socialIconsCount.value : 0))
// const showOverrideAlert = computed(() => process.dev && (nbSocialIcons.value + icons.value.length) > 6)
</script>

<template>
  <footer class="flex-col bg-[#f6f6f6] pt-8">
    <div
      class="footer__top flex flex-wrap lg:flex-nowrap justify-between w-full pb-5 pt-7 p-2 mx-auto w-full px-4 sm:px-6 lg:px-8 max-w-7xl">
      <div
        class="footer__logo text-primary-500 flex justify-center items-center gap-4 flex flex-col lg:justify-between lg:items-start w-full lg:w-1/4">
        <Logo class="h-8" />
        <div class="footer__socials">
          <ul class="flex items-center flex-wrap gap-5 text-primary-500">
            <li class="bg-primary-500 flex items-center justify-center text-white w-[24px] h-[24px] rounded-sm"><a
                href="https://www.linkedin.com/company/pathway" aria-label="pathway linkedin">
                <Icon name="ri:linkedin-fill" class="h-5 w-5" />
              </a></li>
            <li class="bg-primary-500 flex items-center justify-center text-white w-[24px] h-[24px] rounded-sm"><a
                href="https://twitter.com/pathway_com" aria-label="pathway twitter">
                <Icon name="bi:twitter-x" class="h-5 w-5" />
              </a></li>
            <li class="bg-primary-500 flex items-center justify-center text-white w-[24px] h-[24px] rounded-sm"><a
                href="/rss.xml" aria-label="pathway rss feed">
                <Icon name="heroicons:rss-20-solid" class="h-5 w-5" />
              </a></li>
            <li>
              <a :href="socials?.github"
                class="flex items-center gap-2 bg-primary-500 text-white px-2 text-sm transition hover:bg-primary-600 rounded-sm star-count-container h-[28px]"
                target="_blank" aria-label="go to our github">

                <Icon name="mdi:github" class="text-xl focus:text-[#3d51c5]" />
                <span class="flex gap-1 items-center star-count">
                  <span class="font-light">
                    <NavbarGithubStars />k
                  </span>
                  <Icon name="heroicons:star-20-solid" class="mb-[0.1rem]" />
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div
        class="footer__content flex flex-wrap md:flex-nowrap justify-between w-full gap-5 mt-4 lg:mt-0 lg:text-base text-lg">
        <div class="footer__col w-full">
          <span
            class="footer__title border-b-2 pw-text-black border-[#19242F] w-full block font-semibold pb-1 mb-3">Company</span>
          <ul>
            <li>
              <nuxt-link to="/pricing">Pricing</nuxt-link>
            </li>
            <li>
              <nuxt-link to="/success-stories">Success Stories</nuxt-link>
            </li>
            <li>
              <nuxt-link to="/careers">Careers</nuxt-link>
            </li>
            <li>
              <nuxt-link to="/events">Events</nuxt-link>
            </li>
            <li>
              <nuxt-link to="/products">Products</nuxt-link>
            </li>
          </ul>
        </div>
        <div class="footer__col w-full">
          <span
            class="footer__title border-b-2 pw-text-black border-[#19242F] w-full block font-semibold pb-1 mb-3">Developers</span>
          <ul>
            <!-- <li><nuxt-link to="#" class="flex gap-1 items-center"><Logo class="logo-inline text-primary-500" :mobile="true"/> Documentation</nuxt-link></li> -->
            <li>
              <nuxt-link to="/developers/user-guide/introduction/welcome">Documentation</nuxt-link>
            </li>
            <li>
              <nuxt-link to="/developers/templates">App Templates</nuxt-link>
            </li>
            <li>
              <nuxt-link to="/stream-processing-frameworks">Compare Stream Processing Frameworks</nuxt-link>
            </li>
            <li>
              <nuxt-link to="/rag-frameworks">Compare RAG Frameworks</nuxt-link>
            </li>
            <li>
              <nuxt-link to="/flink-alternative">Pathway vs Flink</nuxt-link>
            </li>
            <li>
              <nuxt-link to="/kafka-streams-alternative">Pathway vs Kafka Streams</nuxt-link>
            </li>
            <li>
              <nuxt-link to="/spark-streaming-alternative">Pathway vs Spark Streaming</nuxt-link>
            </li>
            <li>
              <nuxt-link to="/get-license">Get your license</nuxt-link>
            </li>
          </ul>
        </div>
        <div class="footer__col w-full">
          <span
            class="footer__title border-b-2 pw-text-black border-[#19242F] w-full block font-semibold pb-1 mb-3">About</span>
          <ul>
            <li>
              <nuxt-link to="/privacy_gdpr_di">Legal & GDPR</nuxt-link>
            </li>
            <li>
              <nuxt-link to="/privacy_gdpr_di#equal-opportunity-employer">Equal opportunity employer</nuxt-link>
            </li>
            <li>
              <nuxt-link to="/privacy_gdpr_di#privacy-policy">Privacy policy</nuxt-link>
            </li>
            <li>
              <nuxt-link to="/license">Licensing</nuxt-link>
            </li>
            <li>
              <nuxt-link to="/media-kit">Media kit</nuxt-link>
            </li>
            <li>
              <nuxt-link to="/glossary">Glossary</nuxt-link>
            </li>
            <li>
              <nuxt-link to="/friends">Friends</nuxt-link>
            </li>
          </ul>
        </div>
        <div class="footer__col w-full">
          <span
            class="footer__title border-b-2 pw-text-black border-[#19242F] w-full block font-semibold pb-1 mb-3">Contact</span>
          <ul>
            <li>
              <Calendly name="GetStarted" href="https://calendly.com/d/dj2-4jd-3tw/introductory-call-pathway"
                class="button bg-primary-500 p-4 rounded-lg font-semibold mt-4 flex gap-2 items-center">
                Let's talk
                <Icon name="heroicons:chat-bubble-left-right-20-solid" />
              </Calendly>
            </li>
            <li class="mt-3">
              <nuxt-link to="https://discord.com/invite/pathway" class="inline font-bold text-[#3a3a3a]">
                <span>Chat with us on
                  Discord</span>
                <Icon name="ic:baseline-discord" class="inline ml-1 align-middle" />
              </nuxt-link>
            </li>
            <li class="md:mt-4">Pathway</li>
            <li>96bis Boulevard Raspail</li>
            <li>Agoranov</li>
            <li>75006 Paris, France</li>
            <!-- <li><a href="mailto:contact@pathway.com"
                class="underline decoration-solid text-primary-500">contact@pathway.com</a></li> -->
          </ul>
        </div>
      </div>
    </div>
    <div class="footer__bottom pt-7 pb-20">
      <div class="flex flex-wrap justify-between mx-auto w-full px-4 sm:px-6 lg:px-8 max-w-7xl gap-10">
        <div class="footer__details flex flex-wrap gap-10">
          <span class="pw-text-black">&copy; 2021-2024 Pathway</span>
        </div>

      </div>
    </div>

  </footer>
</template>

<style scoped>
.footer__bottom {
  background-color: #F6F6F6;
}

.footer__logo svg {
  height: 2em;
}

.logo-inline {
  height: 1em;
}

.footer__content a {
  &:hover {
    text-decoration: underline;
  }
}

@media (max-width: 768px) {
  .footer__content a {
    display: block;
    padding: 0.5rem 0;
    margin: 0.2rem 0;
  }
}

/* @media (min-width: 1111px) {
  .nav-socials {
    display: none;
  }
} */
</style>
